export const state = () => ({
	inputNumber: 0
});

export const getters = {
	getInputNumber: state => state.inputNumber
};

export const mutations = {
	setInputNumber(state, payload) {
		state.inputNumber = payload;
	}
};
