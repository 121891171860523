<template>
	<Grid v-if="isOrderFee || !!isOrderFeeWishlist" class="order-fee" full-width no-margins>
		<Column :s="6">
			<span>{{ $t('basketorderfee.service') }}</span>
			<!-- <span v-if="isOrderFeeWishlist && this.shopConfig.preregistration">Service order fee</span> -->
		</Column>

		<Column :s="6">
			<h3 class="order-fee__price">{{ orderFee | formatPrice }}</h3>
		</Column>
	</Grid>
</template>

<script>
export default {
	name: 'BasketOrderFee',
	data() {
		return {
			hasProductFee: false
		};
	},
	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		ignoreOrderFee() {
			return this.$store.getters['shopconfig/getIgnoreOrderFee'];
		},

		isOrderFee() {
			// console.log(this.shopConfig.orderServiceFeeWishlist)
			return (
				!this.ignoreOrderFee &&
				Object.keys(this.productsInBasket).length &&
				!this.shopConfig.preregistration &&
				this.orderFee
			);
		},

		isOrderFeeWishlist() {
			return this.shopConfig.orderServiceFeeWishlist;
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		orderFee() {
			/*
			loadProductsFromJson
			productsJsonUrl
			shopJsonUrl
			*/
			if (this.shopConfig.showDetailedServiceFees) {
				// console.log('include fees')
				return this.shopConfig.orderServiceFee;
			} else if (this.shopConfig.orderServiceFee) {
				// console.log('hide fees')
				return this.shopConfig.orderServiceFee;
			} else if (!!this.isOrderFeeWishlist && this.shopConfig.preregistration) {
				// console.log('hide fees wishlist')
				return this.isOrderFeeWishlist;
			} else {
				// console.log('no fees')
				return 0;
			}
		}
	}
};
</script>

<style lang="scss">
$component: 'order-fee';

.#{$component} {
	padding-bottom: 5px;

	&__price {
		text-align: right;
	}
}
</style>
