export default {
	cart: {
		insurance: {
			addon: 'Voeg boekingsbescherming voor al je tickets toe voor slechts',
			toggle: 'Meer informatie',
			xcover: {
				uri: "<a href='https://www.xcover.com/nl/pds/booking-refund-protection-eu' target='_blank'>Meer informatie vind je hier</a>",
				body: 'Deze boekingsbescherming wordt aangeboden en uitgevoerd door onze partner XCover. Voor alle voorwaarden met betrekking tot deze boekingsbescherming verwijzen wij dan ook graag naar hun website.'
			}
		},
		touristTax: {
			body: 'Toeristenbelasting is een bedrag dat door de gemeente wordt gevraagd. In dit geval is dat € 5,- per campingbezoeker.',
			label: 'Toeristenbelasting'
		},
		entertainmentTax: {
			body: "In de kosten van een Grasnapolsky ticket, Camping 't Zilveruitje en een ticket incl. een camper/caravan is een borg inbegrepen. Indien de camper/caravan na afloop weer netjes meegenomen wordt naar huis en/of de gehuurde tent netjes achtergelaten wordt, wordt de borg binnen enkele weken na het Evenement wee sr teruggestort op de rekening van de tickethouder.",
			label: 'Borg'
		}
	},
	home: {
		body: 'Godsallemachtig wat was het weer een feest, onniedan?! Haal dat scheve agendaatje van ow maar gauw tevoorschijn en kras er met varkensstift in: ZWARTE CROSS 2025: 17-18-19-20 juli!<br><br>Inschrijven crossklassen: 2 november 2024<br><br>Harder!-kaartverkoop: 16 november 2024<br><br>Wereldwijde kaartverkoop: 23 november 2024',
		title: 'Ticketshop Zwarte Cross',
		button: 'Shop',
		membersale: 'Shop',
		travelsale: 'Vervoer- en accomodatie verkoop ',
		preregistration: 'Verlanglijst'
	},
	addon: {
		body: {
			loading:
				'We halen je vorige order(s) op. Refresh deze pagina niet, het laden van je vorige order(s) kan tot een minuut duren.',
			noorder:
				"Vul een ordernummer in dat je hebt ontvangen van Paylogic (het ordernummer dat je hebt ontvangen van de hoofdboeker). Zorg ervoor dat je bent ingelogd met het Zwarte Cross-account dat is gebruikt om je order te plaatsen. Als je op zoek bent naar tickets, bezoek dan de <a href='{uri}'>shop</a>.",
			overview: 'Een overzicht van je vorige order(s).'
		},
		title: {
			loading: 'Vorige order(s) checken',
			noorder: 'Er zijn geen vorige orders gevonden.',
			overview: 'Jouw gekochte producten'
		},
		button: {
			lookup: 'Vind order',
			continue: 'Maak je ervaring compleet'
		},
		lookup: {
			failed: 'Er is iets misgegaan aan onze kant. Probeer het later opnieuw',
			invalid: 'Jouw ordernummer lijkt ongeldig, kopieer het juiste nummer uit de Paylogic mail.',
			'no-order':
				'We kunnen geen order vinden met dat ordernummer. Kopieer het juiste ordernummer uit de Paylogic mail.'
		},
		package: {
			addons: 'Je gekozen uitbreidingen in het winkelmandje',
			summary: 'Samenvatting van je gekozen pakket'
		},
		changedorder:
			'Houd er rekening mee dat deze order mogelijk niet de meest recente boekingsgegevens weergeeft, omdat we jouw order op jouw verzoek handmatig hebben gewijzigd. Geen zorgen, we hebben je meest recente boekingsgegevens in ons boekingssysteem en in deze shop vind je alle mogelijke uitbreidingen die geschikt zijn voor je vervoer- en accomodatiepakket.',
		referenceLabel: 'Jouw ordernummer'
	},
	extras: {
		header: {
			body: 'Onderstaande producten zijn af te halen op de Zwarte Cross-camping.',
			title: 'Overige producten'
		}
	},
	popups: {
		shipmentNotAvailable: {
			body: 'Helaas is het niet mogelijk om naar het gekozen land te versturen',
			title: 'De producten zijn verwijderd uit het winkelmandje'
		}
	},
	travel: {
		header: {
			body: '',
			title: 'Vervoer- en accomodatiepakket'
		},
		package: {
			includes: 'Package bevat'
		},
		noticket: {
			title: 'Geselecteerd'
		}
	},
	upsell: {
		cta: 'Meer producten',
		body: 'Voeg deze producten toe aan je winkelmandje',
		title: 'De producten zijn toegevoegd aan je winkelmandje'
	},
	buttons: {
		buyAddons: 'Bestel uitbreidingen',
		learnMore: 'Meer information'
	},
	tickets: {
		header: {
			body: 'Max. 10 tickets per order',
			title: 'TICKETS'
		},
		ticketItem: {
			fee: 'Servicekosten',
			includes: ''
		}
	},
	checkout: {
		header: {
			body: '',
			title: 'Selecteer betaalmethode'
		},
		confetti: {
			message: 'Meer confetti!'
		},
		messages: {
			invalidData: 'Sommige velden bevatten missende of foute gegevens.'
		},
		emailWarning: {
			body: 'Onjuist e-mailadres'
		},
		paymentmethods: {
			message: 'Je betaalmethoden worden geladen'
		}
	},
	wishlist: {
		bodySale:
			"Je kunt nu controleren of je voorkeurstickets en andere items van je verlanglijst nog steeds beschikbaar zijn om te kopen. Zorg ervoor dat je ze aan je winkelmandje toevoegt als ze nog beschikbaar zijn. Product(en) uitverkocht? Je kunt een alternatief kiezen door op de knop 'vervangen' te klikken.",
		bodyLoading:
			'We halen je opgeslagen verlanglijst op. Refresh deze pagina niet, het laden van je verlanglijst kan tot een minuut duren.',
		titleLoading: 'Je verlanglijst wordt gecheckt',
		titleRegular: 'Jouw verlanglijst',
		bodyPreregistration: 'Maak je verlanglijst en bespaar tijd tijdens de verkoop'
	},
	membership: {
		header: {
			body: '',
			title: 'Lidmaatschap'
		},
		disclaimerStep:
			'Het Harder!-lidmaatschap is een periodiek lidmaatschap. Het lidmaatschap wordt 1 jaar vooruit gefactureerd. Deze wordt automatisch verlengd na de vervaldatum, tenzij je jouw lidmaatschap opzegt. Meer informatie over het Harder!-lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>',
		membershipItem: {
			includes: 'Lidmaatschap omvat'
		},
		disclaimerVoucher:
			'If you have a Q-dance Network Credit code you can redeem it here. Please note: if you abandon the checkout process after redeeming your code, your code will be become inactive for approx. 30 mins. Please try again later.',
		disclaimerCheckout:
			'Je staat op het punt om een 1-jarig Harder!-lidmaatschap aan te gaan. Je gaat ermee akkoord dat Abonnementenland 1 jaar vooruit in rekening brengt. Lidmaatschappen worden automatisch verlengd na de vervaldatum, tenzij je je abonnement opzegt. Meer informatie over het Harder!-lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>'
	},
	merchandise: {
		header: {
			body: 'Onderstaande producten worden verstuurd naar het adres dat wordt ingevuld bij het plaatsen van de order.',
			title: 'MERCHANDISE'
		},
		shippingerror: 'Helaas is het niet mogelijk om te verzenden naar het gekozen land.'
	},
	traveladdon: {
		detail: {
			extrasinfo:
				'Als je vragen hebt over de uitbreidingen op deze pagina, ga dan naar de FAQ op de Zwarte Cross website.'
		},
		header: {
			body: '',
			title: 'Jouw reis- en accomodatiepakket(ten)'
		},
		package: {
			includes: 'Pakket omvat'
		},
		noticket: {
			title: 'Geselecteerd'
		}
	},
	paymentstatus: {
		error: {
			header: {
				body: '',
				title: 'Betaling mislukt'
			},
			message:
				'Helaas is er iets misgegaan met je betaling en is je order niet gelukt. Het is mogelijk om de shop opnieuw te betreden, maar houd er rekening mee dat je dan achteraan op de wachtrij komt te staan.'
		},
		failed: {
			header: {
				body: '',
				title: 'Betaling mislukt'
			},
			message:
				'Helaas is er iets misgegaan met je betaling en is je order niet gelukt. Het is mogelijk om de shop opnieuw te betreden, maar houd er rekening mee dat je dan achteraan op de wachtrij komt te staan.'
		},
		pending: {
			header: {
				body: '',
				title: 'Bestelling wordt verwerkt.'
			},
			message:
				'De bestelling is afgerond maar wij wachten nog op de betaling. Zodra wij deze hebben ontvangen, sturen wij je de bevestiging per mail. Houd er rekening mee dat dit enkele minuten kan duren. Als het bedrag is afgeschreven maar je na 30 minuten nog geen bevestiging van ons hebt ontvangen, neem dan contact op met de klantenservice via: <a href="mailto:tickets@feestfabriek.nl">tickets@feestfabriek.nl</a>.'
		},
		canceled: {
			header: {
				body: '',
				title: 'Betaling geannuleerd'
			},
			message:
				'Helaas is er iets misgegaan met je betaling en is je order niet gelukt. Het is mogelijk om de shop opnieuw te betreden, maar houd er rekening mee dat je dan achteraan op de wachtrij komt te staan.'
		},
		completed: {
			header: {
				title: 'YES! HET IS GELUKT!'
			},
			message:
				"<h2>HET AFTELLEN KAN BEGINNEN!</h2><br><h3>En nu?</h3><ul><br><li>Je order wordt verwerkt. Binnen 2 uur ontvang je een orderbevestiging in je mailbox.</li><li>Wanneer je betaling is verwerkt ontvang je een betaalbevestiging.</li><li>Je ontvangt ook een e-mail van onze partner Paylogic met daarin de details van je bestelling en meer info over wanneer je je tickets kunt personaliseren.</li><li>Heb je ook merchandise besteld? Dan wordt je bestelling binnen 2 – 7 werkdagen verzonden. Hiervan ontvang je een track & trace code per mail.<i></ul><br><br><p>Let op: je ontvangt de ticket(s) pas na personalisatie. Personaliseren is mogelijk vanaf 20 november.*<br>Hiervoor onvang je - je raadt het al - een mail van onze partner Paylogic.<br>*Jeugdkaarten hoeven niet gepersonaliseerd te worden. Deze ontvang je zodra de complete order is gepersonaliseerd.</a></p><i></ul><br><br><p>Vragen over je order? <a href='https://www.zwartecross.nl/info/contact/' target='_blank'>Neem contact met ons op</a></p>"
		},
		paymentProvider: {
			adyen: {
				message: ''
			},
			paylogic: {
				message: ''
			}
		}
	},
	savetimeModal: {
		cta: 'Inloggen of registreren',
		body: 'Login en bespaar tijd tijdens het afrekenen',
		title: 'Bespaar tijd'
	},
	transportation: {
		header: {
			body: 'Reis met een georganiseerde busreis',
			title: 'Georganiseerd busvervoer'
		},
		choices: {
			eu: 'Europa',
			nl: 'Nederland',
			title: 'Vanaf waar vertrek je?'
		},
		category: {
			large: 'AANVRAAGFORMULIER GEORGANISEERD BUSVERVOER',
			small: 'Resultaten voor kleine groepen'
		},
		eventTravel: {
			body: {
				list: [
					'Comfortabele stoelen',
					'Airconditioning, toileten & muziek',
					'Neem je eigen drinken mee',
					'Onbeperkte hoeveelheid bagage'
				],
				title: 'Alle busreizen worden uitgevoerd door onze officiële partner Tripticket'
			},
			questions: {
				body: "Contact Tripticket:<br/><br/>+31(0)852733190</br><a href='mailto:help@tripticket.nl'>help@tripticket.nl</a><br/><br/>* Vertrektijden zijn onder voorbehoud. Je ontvangt je tickets ongeveer twee dagen voor vertrek. <Event name> met de definitieve vertrektijden.",
				title: 'Vragen over deze busreis of privé groepsaanvragen?'
			}
		}
	},
	upsellCheckOut: {
		title: 'Anderen kochten ook:'
	},
	membershipMonth: {
		header: {
			body: '',
			title: 'LIDMAATSCHAP'
		},
		disclaimerStep:
			'Het Harder!-lidmaatschap is een periodiek lidmaatschap. Het lidmaatschap wordt 1 maand vooruit gefactureerd. Het lidmaatschap wordt automatisch verlengd na de vervaldatum, tenzij je jouw lidmaatschap opzegt. Meer informatie over het Harder!-lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>',
		membershipItem: {
			includes: 'Membership omvat'
		},
		disclaimerVoucher:
			'If you have a Q-dance Network Credit code you can redeem it here. Please note: if you abandon the checkout process after redeeming your code, your code will become inactive for approx. 30 mins. Please try again later.',
		disclaimerCheckout:
			'Je gaat een lidmaatschap aan voor 1 jaar. Je gaat ermee akkoord dat Abonnementenland 1 maand vooruit in rekening brengt. Je lidmaatschap wordt automatisch verlengd na de vervaldatum, tenzij je jouw lidmaatschap eerder opzegt. Meer informatie over de inhoud van het lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>'
	},
	experiencebuilder: {
		body: 'Welke producten zou je willen toevoegen aan je {type}?',
		title: 'Jouw {type}',
		blocks: {
			extras: {
				slug: 'extras',
				label: 'Overige producten',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			ticket: {
				slug: 'tickets',
				label: 'Tickets',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			travel: {
				slug: 'reizen',
				label: 'reis- en accomodatiepakketten',
				remove: 'Verwijderen',
				update: 'Update Booking'
			},
			upgrade: {
				slug: 'upgrade',
				label: 'Upgrade',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			membership: {
				slug: 'lidmaatschap',
				label: 'Lidmaatschap',
				remove: 'Verwijderen'
			},
			merchandise: {
				slug: 'merchandise',
				label: 'Merchandise',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			traveladdon: {
				slug: "reis extra's",
				label: "Reis- en accomodatiepakketten extra's",
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			transportation: {
				slug: 'vervoer',
				label: 'georganised busvervoer',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			}
		},
		preregister: {
			body: '{typesale}<br/>Harder! verkoop begint',
			title: 'Je hebt je succesvol geregistreerd voor'
		},
		savepreregister: {
			body: "Tijdens de Harder!-kaartverkoop kun je jouw complete verlanglijst toevoegen aan je winkelmandje. Zo kun je meteen al je favoriete producten in één keer bestellen. Houd er wel rekening mee dat bepaalde producten al uitverkocht kunnen zijn, want de verlanglijst geeft je geen garantie op producten.<br/><br/>Vragen over je registratie: <a href='mailto:info@feestfabriek.nl'>info@feestfabriek.nl</a>",
			title: 'Verlanglijst klaar?'
		}
	},
	experienceprebuilder: {
		form: ['Ik ben geïnteresseerd in', 'Ik zal reizen via', 'Hoeveel personen?'],
		header: {
			body: '',
			title: '{festival}'
		},
		bussale: {
			body: 'Boek georganiseerd busvervoer van en naar de {festival}.',
			title: '{festival} Shop'
		},
		addonsale: {
			body: 'Je kunt nu producten zoals kluisjes, parkeerkaarten, reis- en accomodatie pakketten en merchandise aan je winkelmandje toevoegen.',
			title: "{festival} extra's shop"
		},
		membersale: {
			body: 'Maak deel uit van een nieuwe Zwarte Cross-ervaring. Koop nu je ticket en extra merchandise voor de Zwarte Cross.',
			title: '{festival}'
		},
		prebuilder: {
			title: "Klik op 'Alleen tickets' om tickets te bestellen. Ga je voor de alles-in-één ervaring inclusief verblijf? Klik op 'Reis- en Tickets' voor alle reis- en accomodatiepakketten."
		},
		travelsale: {
			body: 'Je kunt nu je reis- & accomodatiepakket inclusief alle andere beschikbare opties toevoegen aan je winkelmandje. Als je tevreden bent met je order, ga dan door naar de kassa. Houd er rekening mee dat je slechts 20 minuten hebt om je betaling af te ronden.',
			title: '{festival} reis- en accomodatie Harder!-kaartverkoop'
		},
		prebuilderAddon: {
			title: "Klik op 'Alleen tickets' om tickets te bestellen. Ga je voor de alles-in-één ervaring inclusief verblijf? Klik op 'Reis & Tickets'  voor alle reis- en accomodatiepakketten."
		},
		preregistration: {
			body: "Je kunt nu producten zoals tickets, reis- en accomodatiepakketten, merchandise en extra's toevoegen aan je verlanglijst. Je verlanglijst kan worden opgeslagen in je account.",
			title: '{festival} wishlist'
		},
		preregistrationprebuilder: {
			body: 'Registreer nu voor {festival} tickets of reis- en accomodatiepakketten.',
			title: '{festival} registratie'
		}
	},

	cart: {
		insurance: {
			addon: 'Voeg boekingsbescherming voor al je tickets toe voor slechts',
			toggle: 'Meer informatie',
			xcover: {
				uri: "<a href='https://www.xcover.com/nl/pds/booking-refund-protection-eu' target='_blank'>Meer informatie vind je hier</a>",
				body: 'Deze boekingsbescherming wordt aangeboden en uitgevoerd door onze partner XCover. Voor alle voorwaarden met betrekking tot deze boekingsbescherming verwijzen wij dan ook graag naar hun website.'
			}
		},
		touristTax: {
			body: 'Toeristenbelasting is een bedrag dat door de gemeente wordt gevraagd. In dit geval is dat € 5,- per campingbezoeker.',
			label: 'Toeristenbelasting'
		},
		entertainmentTax: {
			body: "In de kosten van een Grasnapolsky ticket, Camping 't Zilveruitje en een ticket incl. een camper/caravan is een borg inbegrepen. Indien de camper/caravan na afloop weer netjes meegenomen wordt naar huis en/of de gehuurde tent netjes achtergelaten wordt, wordt de borg binnen enkele weken na het Evenement weer teruggestort op de rekening van de tickethouder.",
			label: 'Borg'
		}
	},
	home: {
		body: 'Godsallemachtig wat was het weer een feest, onniedan?! Haal dat scheve agendaatje van ow maar gauw tevoorschijn en kras er met varkensstift in: ZWARTE CROSS 2025: 17-18-19-20 juli!<br><br>Inschrijven crossklassen: 2 november 2024<br><br>Harder!-kaartverkoop: 16 november 2024<br><br>Wereldwijde kaartverkoop: 23 november 2024',
		title: 'Ticketshop Zwarte Cross',
		button: 'Shop',
		membersale: 'Shop',
		travelsale: 'Vervoer- en accomodatie verkoop ',
		preregistration: 'Verlanglijst'
	},
	addon: {
		body: {
			loading:
				'We halen je vorige order(s) op. Refresh deze pagina niet, het laden van je vorige order(s) kan tot een minuut duren.',
			noorder:
				"Vul een ordernummer in dat je hebt ontvangen van Paylogic (het ordernummer dat je hebt ontvangen van de hoofdboeker). Zorg ervoor dat je bent ingelogd met het Zwarte Cross-account dat is gebruikt om je order te plaatsen. Als je op zoek bent naar tickets, bezoek dan de <a href='{uri}'>shop</a>.",
			overview: 'Een overzicht van je vorige order(s).'
		},
		title: {
			loading: 'Vorige order(s) checken',
			noorder: 'Er zijn geen vorige orders gevonden.',
			overview: 'Jouw gekochte producten'
		},
		button: {
			lookup: 'Vind order',
			continue: 'Maak je ervaring compleet'
		},
		lookup: {
			failed: 'Er is iets misgegaan aan onze kant. Probeer het later opnieuw',
			invalid: 'Jouw ordernummer lijkt ongeldig, kopieer het juiste nummer uit de Paylogic mail.',
			'no-order':
				'We kunnen geen order vinden met dat ordernummer. Kopieer het juiste ordernummer uit de Paylogic mail.'
		},
		package: {
			addons: 'Je gekozen uitbreidingen in het winkelmandje',
			summary: 'Samenvatting van je gekozen pakket'
		},
		changedorder:
			'Houd er rekening mee dat deze order mogelijk niet de meest recente boekingsgegevens weergeeft, omdat we jouw order op jouw verzoek handmatig hebben gewijzigd. Geen zorgen, we hebben je meest recente boekingsgegevens in ons boekingssysteem en in deze shop vind je alle mogelijke uitbreidingen die geschikt zijn voor je vervoer- en accomodatiepakket.',
		referenceLabel: 'Jouw ordernummer'
	},
	extras: {
		header: {
			body: 'Onderstaande producten zijn af te halen op de Zwarte Cross-camping.',
			title: 'Overige producten'
		}
	},
	popups: {
		shipmentNotAvailable: {
			body: 'Helaas is het niet mogelijk om naar het gekozen land te versturen',
			title: 'De producten zijn verwijderd uit het winkelmandje'
		}
	},
	travel: {
		header: {
			body: '',
			title: 'Vervoer- en accomodatiepakket'
		},
		package: {
			includes: 'Package bevat'
		},
		noticket: {
			title: 'Geselecteerd'
		}
	},
	upsell: {
		cta: 'Meer producten',
		body: 'Voeg deze producten toe aan je winkelmandje',
		title: 'De producten zijn toegevoegd aan je winkelmandje'
	},
	buttons: {
		buyAddons: 'Bestel uitbreidingen',
		learnMore: 'Meer information'
	},
	tickets: {
		header: {
			body: 'Max. 10 tickets per order',
			title: 'TICKETS'
		},
		ticketItem: {
			fee: 'Servicekosten',
			includes: ''
		}
	},
	checkout: {
		header: {
			body: '',
			title: 'Selecteer betaalmethode'
		},
		confetti: {
			message: 'Meer confetti!'
		},
		messages: {
			invalidData: 'Sommige velden bevatten missende of foute gegevens.'
		},
		emailWarning: {
			body: 'Onjuist e-mailadres'
		},
		paymentmethods: {
			message: 'Je betaalmethoden worden geladen'
		}
	},
	wishlist: {
		bodySale:
			"Je kunt nu controleren of je voorkeurstickets en andere items van je verlanglijst nog steeds beschikbaar zijn om te kopen. Zorg ervoor dat je ze aan je winkelmandje toevoegt als ze nog beschikbaar zijn. Product(en) uitverkocht? Je kunt een alternatief kiezen door op de knop 'vervangen' te klikken.",
		bodyLoading:
			'We halen je opgeslagen verlanglijst op. Refresh deze pagina niet, het laden van je verlanglijst kan tot een minuut duren.',
		titleLoading: 'Je verlanglijst wordt gecheckt',
		titleRegular: 'Jouw verlanglijst',
		bodyPreregistration: 'Maak je verlanglijst en bespaar tijd tijdens de verkoop'
	},
	membership: {
		header: {
			body: '',
			title: 'Lidmaatschap'
		},
		disclaimerStep:
			'Het Harder!-lidmaatschap is een periodiek lidmaatschap. Het lidmaatschap wordt 1 jaar vooruit gefactureerd. Deze wordt automatisch verlengd na de vervaldatum, tenzij je jouw lidmaatschap opzegt. Meer informatie over het Harder!-lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>',
		membershipItem: {
			includes: 'Lidmaatschap omvat'
		},
		disclaimerVoucher:
			'If you have a Q-dance Network Credit code you can redeem it here. Please note: if you abandon the checkout process after redeeming your code, your code will be become inactive for approx. 30 mins. Please try again later.',
		disclaimerCheckout:
			'Je staat op het punt om een 1-jarig Harder!-lidmaatschap aan te gaan. Je gaat ermee akkoord dat Abonnementenland 1 jaar vooruit in rekening brengt. Lidmaatschappen worden automatisch verlengd na de vervaldatum, tenzij je je abonnement opzegt. Meer informatie over het Harder!-lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>'
	},
	merchandise: {
		header: {
			body: 'Onderstaande producten worden verstuurd naar het adres dat wordt ingevuld bij het plaatsen van de order.',
			title: 'MERCHANDISE'
		},
		shippingerror: 'Helaas is het niet mogelijk om te verzenden naar het gekozen land.'
	},
	traveladdon: {
		detail: {
			extrasinfo:
				'Als je vragen hebt over de uitbreidingen op deze pagina, ga dan naar de FAQ op de Zwarte Cross website.'
		},
		header: {
			body: '',
			title: 'Jouw reis- en accomodatiepakket(ten)'
		},
		package: {
			includes: 'Pakket omvat'
		},
		noticket: {
			title: 'Geselecteerd'
		}
	},
	paymentstatus: {
		error: {
			header: {
				body: '',
				title: 'Betaling mislukt'
			},
			message:
				'Helaas is er iets misgegaan met je betaling en is je order niet gelukt. Het is mogelijk om de shop opnieuw te betreden, maar houd er rekening mee dat je dan achteraan op de wachtrij komt te staan.'
		},
		failed: {
			header: {
				body: '',
				title: 'Betaling mislukt'
			},
			message:
				'Helaas is er iets misgegaan met je betaling en is je order niet gelukt. Het is mogelijk om de shop opnieuw te betreden, maar houd er rekening mee dat je dan achteraan op de wachtrij komt te staan.'
		},
		pending: {
			header: {
				body: '',
				title: 'Bestelling wordt verwerkt.'
			},
			message:
				'De bestelling is afgerond maar wij wachten nog op de betaling. Zodra wij deze hebben ontvangen, sturen wij je de bevestiging per mail. Houd er rekening mee dat dit enkele minuten kan duren. Als het bedrag is afgeschreven maar je na 30 minuten nog geen bevestiging van ons hebt ontvangen, neem dan contact op met de klantenservice via: <a href="mailto:tickets@feestfabriek.nl">tickets@feestfabriek.nl</a>.'
		},
		canceled: {
			header: {
				body: '',
				title: 'Betaling geannuleerd'
			},
			message:
				'Helaas is er iets misgegaan met je betaling en is je order niet gelukt. Het is mogelijk om de shop opnieuw te betreden, maar houd er rekening mee dat je dan achteraan op de wachtrij komt te staan.'
		},
		completed: {
			header: {
				title: 'YES! HET IS GELUKT!'
			},
			message:
				"<h2>HET AFTELLEN KAN BEGINNEN!</h2><br><h3>En nu?</h3><ul><br><li>Je order wordt verwerkt. Binnen 2 uur ontvang je een orderbevestiging in je mailbox.</li><li>Wanneer je betaling is verwerkt ontvang je een betaalbevestiging.</li><li>Je ontvangt ook een e-mail van onze partner Paylogic met daarin de details van je bestelling en meer info over wanneer je je tickets kunt personaliseren.</li><li>Heb je ook merchandise besteld? Dan wordt je bestelling binnen 2 – 7 werkdagen verzonden. Hiervan ontvang je een track & trace code per mail.<i></ul><br><br><p>Let op: je ontvangt de ticket(s) pas na personalisatie. Personaliseren is mogelijk vanaf 20 november.*<br>Hiervoor onvang je - je raadt het al - een mail van onze partner Paylogic.<br>*Jeugdkaarten hoeven niet gepersonaliseerd te worden. Deze ontvang je zodra de complete order is gepersonaliseerd.</a></p><i></ul><br><br><p>Vragen over je order? <a href='https://www.zwartecross.nl/info/contact/' target='_blank'>Neem contact met ons op</a></p>"
		}
	},
	savetimeModal: {
		cta: 'Inloggen of registreren',
		body: 'Login en bespaar tijd tijdens het afrekenen',
		title: 'Bespaar tijd'
	},
	transportation: {
		header: {
			body: 'Reis met een georganiseerde busreis',
			title: 'Georganiseerd busvervoer'
		},
		choices: {
			eu: 'Europa',
			nl: 'Nederland',
			title: 'Vanaf waar vertrek je?'
		},
		category: {
			large: 'AANVRAAGFORMULIER GEORGANISEERD BUSVERVOER',
			small: 'Resultaten voor kleine groepen'
		},
		eventTravel: {
			body: {
				list: [
					'Comfortabele stoelen',
					'Airconditioning, toileten & muziek',
					'Neem je eigen drinken mee',
					'Onbeperkte hoeveelheid bagage'
				],
				title: 'Alle busreizen worden uitgevoerd door onze officiële partner Tripticket'
			},
			questions: {
				body: "Contact Tripticket:<br/><br/>+31(0)852733190</br><a href='mailto:help@tripticket.nl'>help@tripticket.nl</a><br/><br/>* Vertrektijden zijn onder voorbehoud. Je ontvangt je tickets ongeveer twee dagen voor vertrek. <Event name> met de definitieve vertrektijden.",
				title: 'Vragen over deze busreis of privé groepsaanvragen?'
			}
		}
	},
	upsellCheckOut: {
		title: 'Anderen kochten ook:'
	},
	membershipMonth: {
		header: {
			body: '',
			title: 'LIDMAATSCHAP'
		},
		disclaimerStep:
			'Het Harder!-lidmaatschap is een periodiek lidmaatschap. Het lidmaatschap wordt 1 maand vooruit gefactureerd. Het lidmaatschap wordt automatisch verlengd na de vervaldatum, tenzij je jouw lidmaatschap opzegt. Meer informatie over het Harder!-lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>',
		membershipItem: {
			includes: 'Membership omvat'
		},
		disclaimerVoucher:
			'If you have a Q-dance Network Credit code you can redeem it here. Please note: if you abandon the checkout process after redeeming your code, your code will become inactive for approx. 30 mins. Please try again later.',
		disclaimerCheckout:
			'Je gaat een lidmaatschap aan voor 1 jaar. Je gaat ermee akkoord dat Abonnementenland 1 maand vooruit in rekening brengt. Je lidmaatschap wordt automatisch verlengd na de vervaldatum, tenzij je jouw lidmaatschap eerder opzegt. Meer informatie over de inhoud van het lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>'
	},
	experiencebuilder: {
		body: 'Welke producten zou je willen toevoegen aan je {type}?',
		title: 'Jouw {type}',
		blocks: {
			extras: {
				slug: 'extras',
				label: 'Overige producten',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			ticket: {
				slug: 'tickets',
				label: 'Tickets',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			travel: {
				slug: 'reizen',
				label: 'reis- en accomodatiepakketten',
				remove: 'Verwijderen',
				update: 'Update Booking'
			},
			upgrade: {
				slug: 'upgrade',
				label: 'Upgrade',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			membership: {
				slug: 'lidmaatschap',
				label: 'Lidmaatschap',
				remove: 'Verwijderen'
			},
			merchandise: {
				slug: 'merchandise',
				label: 'Merchandise',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			traveladdon: {
				slug: "reis extra's",
				label: "Reis- en accomodatiepakketten extra's",
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			transportation: {
				slug: 'vervoer',
				label: 'georganised busvervoer',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			}
		},
		preregister: {
			body: '{typesale}<br/>Harder! verkoop begint',
			title: 'Je hebt je succesvol geregistreerd voor'
		},
		savepreregister: {
			body: "Tijdens de Harder!-kaartverkoop kun je jouw complete verlanglijst toevoegen aan je winkelmandje. Zo kun je meteen al je favoriete producten in één keer bestellen. Houd er wel rekening mee dat bepaalde producten al uitverkocht kunnen zijn, want de verlanglijst geeft je geen garantie op producten.<br/><br/>Vragen over je registratie: <a href='mailto:info@feestfabriek.nl'>info@feestfabriek.nl</a>",
			title: 'Verlanglijst klaar?'
		}
	},
	experienceprebuilder: {
		form: ['Ik ben geïnteresseerd in', 'Ik zal reizen via', 'Hoeveel personen?'],
		header: {
			body: '',
			title: '{festival}'
		},
		bussale: {
			body: 'Boek georganiseerd busvervoer van en naar de {festival}.',
			title: '{festival} Shop'
		},
		addonsale: {
			body: 'Je kunt nu producten zoals kluisjes, parkeerkaarten, reis- en accomodatie pakketten en merchandise aan je winkelmandje toevoegen.',
			title: "{festival} extra's shop"
		},
		membersale: {
			body: 'Maak deel uit van een nieuwe Zwarte Cross-ervaring. Koop nu je ticket en extra merchandise voor de Zwarte Cross.',
			title: '{festival}'
		},
		prebuilder: {
			title: "Klik op 'Alleen tickets' om tickets te bestellen. Ga je voor de alles-in-één ervaring inclusief verblijf? Klik op 'Reis- en Tickets' voor alle reis- en accomodatiepakketten."
		},
		travelsale: {
			body: 'Je kunt nu je reis- & accomodatiepakket inclusief alle andere beschikbare opties toevoegen aan je winkelmandje. Als je tevreden bent met je order, ga dan door naar de kassa. Houd er rekening mee dat je slechts 20 minuten hebt om je betaling af te ronden.',
			title: '{festival} reis- en accomodatie Harder!-kaartverkoop'
		},
		prebuilderAddon: {
			title: "Klik op 'Alleen tickets' om tickets te bestellen. Ga je voor de alles-in-één ervaring inclusief verblijf? Klik op 'Reis & Tickets'  voor alle reis- en accomodatiepakketten."
		},
		preregistration: {
			body: "Je kunt nu producten zoals tickets, reis- en accomodatiepakketten, merchandise en extra's toevoegen aan je verlanglijst. Je verlanglijst kan worden opgeslagen in je account.",
			title: '{festival} wishlist'
		},
		preregistrationprebuilder: {
			body: 'Registreer nu voor {festival} tickets of reis- en accomodatiepakketten.',
			title: '{festival} registratie'
		}
	},
	navigation: {
		overview: 'NL Overzicht',
		wishlist: 'Verlanglijst',
		previous: 'Vorige order(s)'
	}
};
