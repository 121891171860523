<template>
	<div class="input-number-field">
		<div
			class="input-number-field__input-wrapper"
			:class="{
				'input-number-field__input-wrapper--disabled': inputIsDisabled,
				'input-number-field__input-wrapper--dark': isDark,
				'input-number-field__input-wrapper--small': isSmall
			}"
		>
			<button class="input-number-field__icon" @click="onClickMinus" :disabled="inputIsZero">
				<span>-</span>
			</button>

			<input
				class="input-number-field__input"
				type="number"
				readonly
				:disabled="inputIsDisabled"
				:value="value"
				@input="onInput"
			/>

			<button
				class="input-number-field__icon"
				:class="{ 'input-number-field__icon--disabled': maxReached }"
				@click="onClickPlus"
				:disabled="inputIsDisabled || maxReached"
			>
				<span>+</span>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InputNumberField',

	props: {
		value: {
			type: Number,
			default: 0
		},

		minValue: {
			type: Number,
			default: 0
		},

		maxValue: {
			type: Number,
			default: 9
		},

		stock: {
			type: Number,
			default: 0
		},

		isDisabled: {
			type: Boolean,
			default: false
		},

		isDark: {
			type: Boolean,
			default: false
		},
		isSmall: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: null
		}
	},

	computed: {
		inputIsDisabled() {
			return this.isDisabled || this.stock < 1;
		},

		inputIsZero() {
			return this.value === 0;
		},

		maxAmount() {
			return Math.min(this.stock, this.maxValue);
		},

		maxReached() {
			return !this.inputIsDisabled && this.value >= this.maxAmount;
		}
	},

	methods: {
		onInput(e) {
			// Only useful when user can type a number using the keyboard
			// const val = parseInt(e.currentTarget.value);
			// !this.isDisabled && this.$emit('input',  Math.max(this.minValue, Math.min(val, this.maxAmount)));
		},

		onClickMinus() {
			if (this.type === 'ticket-stepper' && this.minValue && this.value <= this.minValue) {
				this.$emit('input', this.value - this.minValue);
				return;
			}

			const nextAmount = Math.max(this.value - 1, this.minValue);

			if (nextAmount !== this.value) {
				this.$emit('input', nextAmount);
			}
		},

		onClickPlus() {
			// const nextAmount = Math.min(this.value + 1, this.maxAmount);

			if (this.type === 'ticket-stepper' && this.minValue && this.value < this.minValue) {
				this.$emit('input', this.value + this.minValue);
				return;
			}

			// if (nextAmount !== this.value && !this.inputIsDisabled) {
			if (!this.maxReached && !this.inputIsDisabled) {
				this.$emit('input', this.value + 1);
			}
		}
	},

	mounted() {}
};
</script>

<style lang="scss">
$component: 'input-number-field';

.#{$component} {
	&__input-wrapper {
		position: relative;

		display: flex;
		justify-content: space-between;
		align-items: center;

		height: 50px;

		color: $color-white;

		border-radius: 20px;

		&--disabled {
			> * {
				opacity: 0.5;
			}
		}

		&--dark {
			.#{$component}__input {
				background-color: rgba($color-grey, 0.1);
				color: $color-white;
			}
		}

		&--small {
			height: 50px;
		}
	}

	&__input {
		display: block;
		width: calc(100% - 102px);
		height: 100%;
		font-size: 20px;
		color: $color-black;
		text-align: center;
		background-color: #d8d8d8;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	&__icon {
		position: relative;

		flex-shrink: 0;

		display: flex;
		justify-content: center;
		align-items: center;

		height: 100%;
		width: 50px;

		font-family: $font-bold;
		font-size: 24px;
		line-height: 1;
		text-shadow: var(--primary-body-color-dark);

		background-color: rgba(var(--button-alternative-background-color), 1);
		color: var(--button-alternative-text-color);

		span {
			margin-top: -4px;
		}

		&:disabled,
		&--disabled {
			opacity: 0.5;

			cursor: default;
		}

		&:not(:disabled):not(&--disabled) {
			html.no-touchevents & {
				transition: opacity 0.3s $ease-out-cubic;

				&:hover {
					opacity: 0.6;
				}
			}
		}
	}
}
</style>
