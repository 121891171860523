<template>

	<div class="extra-item">

		<div v-if="data.meta.image" class="extra-item__image" :class="{ 'md-lg-only': data.hideVisualOnMobile }">

			<img :src="cdnUrl(data.meta.image)" alt="" />

		</div>

		<div class="extra-item__content">

			<h3 class="extra-item__title">

				<span>{{ data.name }}</span>

			</h3>

			<span class="extra-item__price" v-if="showsoldoutWithPrice"> {{ data.price | formatPrice }} </span>

			<span class="extra-item__stock">

				<span v-if="data.stockAvailable > 0">

					<In-stock class="extra-item__stock-icon" />
					 {{ $t('extraitem.instock') }}
				</span>

				<span v-else>

					<Out-stock class="extra-item__stock-icon extra-item__stock-icon--out" />
					 {{ $t('extraitem.soldout') }}
				</span>

			</span>

			<div class="extra-item__description" v-html="data.meta.description"></div>

			<InputNumberField
				class="extra-item__input"
				v-model="numberOfProducts"
				:isDisabled="isShopUpdatePending"
				:stock="data.stockAvailable"
				:maxValue="data.maxQuantity"
			/>

		</div>

	</div>

</template>

<script>
import InputNumberField from '@/components/ui/InputNumberField';

export default {
	name: 'ExtraItem',

	components: {
		InputNumberField
	},

	props: {
		data: Object
	},

	computed: {
		showsoldoutWithPrice() {
			if (this.data.stockAvailable <= 0) {
				if (!this.shopConfig.soldoutPrice) {
					return false;
				}
				return true;
			}
			return true;
		},
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		isShopUpdatePending() {
			return this.$store.getters['shopapi/getIsShopUpdatePending'];
		},

		numberOfProducts: {
			get() {
				return this.shopCart.products[this.data.id] || 0;
			},

			set(val) {
				// this.$store.commit('ui/setInputNumber', parseInt(val));

				const payload = {
					url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products`,

					params: {
						productId: this.data.id,
						quantity: val
					},
					datalayerQuantity: 1
				};

				const tempData = {
					name: this.data.name,
					price: this.data.price,
					productId: this.data.id,
					amount: val - this.numberOfProducts,
					shop: this.$store.getters['shopconfig/getData'].titleFull,
					type: this.data.mappedType
				};

				// if (val - this.numberOfProducts == 1) {
				// 	this.addToDataLayer({
				// 		products: [tempData]
				// 	});
				// } else {
				// 	this.removeFromDataLayer({
				// 		products: [tempData]
				// 	});
				// }

				this.$store
					.dispatch(`shopapi/putRequest`, payload)
					.then(response => {
						if (this.data.isUpsell && window.dataLayer) {
							window.dataLayer.push({
								event: 'upsellClick',
								eventLabel: `${this.shopConfig.festivalData.title}${
									this.data.isCart ? ' - Cart' : ' - Overlay'
								} `,
								eventCategory: this.data.name,
								eventAction: 'Add to cart'
							});
						}
						// console.log('api', response.data.data);
					})
					.catch(error => {
						this.apiErrorMsg =
							error.response &&
							error.response.data &&
							error.response.data.error &&
							error.response.data.error.message;

						if (this.apiErrorMsg) {
							this.$store.commit('setModalMessage', error.response.data.error.message); // message that gets shown by 8000 error modal
							this.$fireErrorEvent(8000);
						} else {
							const errorText = this.$t('generalError.body');
							const formatedError = this.apiErrorMsg
								? `${error.response.data.error.message}, cartId:${this.shopCart.id}`
								: `${errorText} cartId:${this.shopCart.id}`;
							this.$fireErrorEvent(formatedError);
						}
					});
			}
		},

		extraData() {
			return this.$store.getters['shopapi/getProducts'] && this.$store.getters['shopapi/getProducts'].extras;
		}
	}
};
</script>

<style lang="scss">
$component: 'extra-item';

.#{$component} {
	display: flex;
	flex-direction: column;

	background-color: var(--card-bg-color);
	// border: 2px solid $color-white;

	// color: $color-black;
	color: var(--primary-body-color);

	// box-shadow: inset 0px 0px 0px 2px #c5c5c5;

	height: 100%;
	padding: 4px;

	&__content {
		padding: 15px;
	}

	&__title {
		font-size: 20px;
		text-transform: uppercase;
	}

	&__price {
		display: block;
		padding-top: 25px;
	}

	&__stock {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-family: $font-bold;
		font-size: 12px;
	}

	&__description {
		padding: 20px 0 50px;

		font-size: 16px;
		line-height: 24px;
	}

	&__stock-icon {
		width: 10px;
		path {
			fill: green;
		}

		&--out {
			path {
				fill: red;
			}
		}
	}

	@include md-lg {
		&__content {
			display: flex;
			flex-direction: column;

			height: 100%;
		}

		&__description {
			flex: auto;
		}
	}

	@include sm {
		&__description {
			padding: 20px 0 30px;
		}
	}
}
</style>

