<template>
	<nav class="navigation" :class="{ 'navigation--has-counter': showCounter }" v-if="render">
		<div class="navigation__left">
			<transition name="fade" mode="out-in">
				<nuxt-link
					class="navigation__link"
					:to="{ path: '/', query: $route.query }"
					v-if="showLogoOrBackButton || isPaymentStatusRoute"
					key="logo"
				>
					<img class="navigation__icon" :src="getBrandLogo" />
				</nuxt-link>

				<button class="navigation__back" v-else-if="!isCheckoutStatusRoute" @click="back" key="back">
					<Arrow-down class="navigation__arrow" />

					<span cxs-hide>{{ $t('label.navigation.overview') }}</span>
				</button>
			</transition>
		</div>

		<div class="navigation__mid">
			<StepsLeftProgressBar
				:steps="getRouteSteps().steps"
				:activeIndex="getRouteSteps().activeIndex"
				v-if="!isOverviewRoute && !isPaymentStatusRoute && !isCheckoutStatusRoute && showProgressBar"
			/>
		</div>

		<div class="navigation__right">
			<button
				@click="toggleWishlist"
				v-if="!isCheckoutStatusRoute && shopConfig && !isPaymentStatusRoute && hasWishlist"
			>
				<span>Wishlist</span>
			</button>

			<button
				@click="toggleWishlist"
				v-if="shopConfig && !isPaymentStatusRoute && isAddonShop && hasPreviousOrder"
			>
				<span>{{ $t('navigation.previous') }}</span>
			</button>

			<UserProfile v-if="!isCheckoutStatusRoute" />

			<CartIndicator class="navigation__cart" v-if="!isCheckoutStatusRoute" />

			<CartNotification
				class="navigation__cart-notification"
				v-if="shopConfig && shopConfig.stepflow === 'No' && shopConfig.upsell === 'No'"
			/>
		</div>
	</nav>
</template>

<script>
import CartIndicator from '@/components/shop/CartIndicator';
import CartNotification from '@/components/shop/CartNotification';
import UserProfile from '@/components/ui/UserProfile';
import StepsLeftProgressBar from '@/components/ui/StepsLeftProgressBar';

export default {
	name: 'Navigation',

	data() {
		return {
			render: false
		};
	},

	components: {
		CartIndicator,
		CartNotification,
		UserProfile,
		StepsLeftProgressBar
	},

	computed: {
		isCheckoutStatusRoute() {
			return this.$route.name === `slug-checkout`;
		},
		hasConfig() {
			return this.$store.getters.getHasConfig;
		},

		getBrandLogo() {
			return this.cdnUrl(this.$store.getters.getBrandLogo);
		},

		wishlist() {
			return this.$store.getters['sso/getWishlist'];
		},

		hasWishlist() {
			return (
				this.wishlist &&
				this.wishlist.wishlist &&
				this.wishlist.wishlist.products &&
				Object.entries(this.wishlist.wishlist.products).length !== 0
			);
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		showCounter() {
			return this.$store.getters.getShowCounter;
		},

		// ! delete this some time
		showLogoOrBackButton() {
			return this.$route.path.split('/').length <= 2;
		},

		isOverviewRoute() {
			return this.$route.name === 'slug' && this.$route.path.split('/').length >= 1;
		},

		isPaymentStatusRoute() {
			return this.$route.name === `slug-paymentstatus`;
		},

		isCheckoutStatusRoute() {
			return this.$route.name === `slug-checkout`;
		},

		routes() {
			return this.$store.getters.getShopRoutingFlow;
		},

		currRouteIndex() {
			return this.routes.findIndex(item => item.name === this.$route.name);
		},

		prevRoute() {
			if (this.$route.name === 'slug-travel-detail') {
				return this.routes.find(item => item.name === 'slug-travel');
			} else {
				return this.currRouteIndex > 0 ? this.routes[this.currRouteIndex - 1] : null;
			}
		},

		paylogicToken() {
			return this.$store.getters['shopconfig/getPaylogictoken'];
		},

		isAddonShop() {
			return this.shopConfig.shopInit && this.shopConfig.shopInit.isAddOnShop;
			// return this.shopConfig.shopInit && true
		},

		hasPreviousOrder() {
			const prevOrder = this.$store.getters['shopapi/getPurchasedProducts'];
			return prevOrder;
		},

		showProgressBar() {
			return this.$store.getters['shopconfig/getShowProgressBar'];
		}
	},

	watch: {
		shopConfig() {
			if (this.shopConfig && !this.isPaymentStatusRoute && !this.isCheckoutStatusRoute && this.hasWishlist) {
				this.toggleWishlist();
			}
		}
	},

	methods: {
		back() {
			// if (this.prevRoute) {
			// 	this.$router.push({ name: this.prevRoute.name });
			// } else {
			this.$router.push({
				name: 'slug',
				query: this.$route.query
			});
			// }
		},

		toggleWishlist() {
			this.$store.commit('setModalPrefillCartActive', true);
		},

		getRouteSteps() {
			if (Array.isArray(this.routes) && this.routes.length > 0) {
				const activeIndex = this.routes.findIndex(route => route.path === this.$route.path);
				let steps = this.routes.map((route, idx) => {
					return {
						label: route.title,
						completed: idx < activeIndex,
						path: route.path,
						type: route.type
					};
				});

				// Checkout
				steps.push({
					label: 'Checkout',
					completed: false,
					route: 'slug-checkout',
					type: 'checkout'
				});

				return { activeIndex, steps };
			}

			return null;
		}
	},

	mounted() {
		this.render = true;
		if (
			this.shopConfig &&
			!this.isPaymentStatusRoute &&
			!this.isCheckoutStatusRoute &&
			(this.shopConfig.travelsale || this.shopConfig.membersale) &&
			this.hasWishlist
		) {
			this.toggleWishlist();
		}
	}
};
</script>

<style lang="scss">
$component: 'navigation';

.#{$component} {
	position: relative;
	z-index: $layer-navigation;
	display: grid;
	grid-template-columns: auto auto auto;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: $nav-height;
	padding: 1rem $global-spacing;
	color: var(--navbar-text-color);
	background: rgba(var(--navbar-bg-color), 0.9);

	&--has-counter {
		top: $counter-height;
	}

	&__left {
		line-height: 0;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		gap: 2rem;
		.#{$component} {
			&__back {
				color: var(--navigation-top-bar-links);
			}
		}
	}

	&__mid {
		display: flex;
		justify-content: center;
	}

	&__right {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		body.checkout-open & {
			padding-right: 5px;
		}

		> * {
			&:not(:first-child) {
				margin-left: $global-spacing;
			}
		}
	}

	&__back {
		display: flex;
		align-items: center;

		text-transform: uppercase;
		font-family: $font-bold;
		// color:red;
	}

	&__arrow {
		transform: rotate(90deg);
		width: 20px;

		margin-right: $global-spacing;
	}

	&__cart-notification {
		position: absolute;
		top: 65px;
		right: 0;

		body.checkout-open & {
			right: 5px;
		}
	}

	&__icon {
		max-height: 44px;
	}

	@include sm {
		height: $nav-height-mob;

		padding: 0 $global-spacing--mobile;

		&__right {
			> * {
				&:not(:first-child) {
					margin-left: $global-spacing;
				}
			}
		}

		&__link {
			height: 100%;
			display: inline-block;
			color: red;
		}

		&__icon {
			height: 30px;
			vertical-align: middle;
		}

		&__arrow {
			margin-right: $global-spacing--mobile;
		}

		&__cart-notification {
			top: 40px;
		}
	}
}
</style>
