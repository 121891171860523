// Try to keep the keys in this translation file in ascending alphabetical order in order to quickly find translations
export default {
	// ALERTS
	alert: {
		complete_order_in_time: 'Be sure to complete your order before the timer runs out.',
		complete_payment_in_time: 'Be sure to complete your payment before the timer runs out.',
		no_stock: 'This item is out of stock. You can select an alternative.',
		no_refund_choice: 'You have not yet made a choice with regard to booking protection.',
		no_checkout: 'You cannot checkout yet because:',
		sold_out: 'This item is sold out. You can select an alternative.'
	},
	// CART MODAL
	checkout: {
		calculate: 'Bereken verzendkosten',
		entertainment_tax: {
			title: 'Local Entertainment Tax',
			lead: 'The city of Amsterdam implemented an Entertainment Tax. If you make use of transport or entertainment as a paying customer, you have to pay an entertainment tax (vermakelijkhedenretributie, vmr). From 1 January 2023, the event entertainment tax (vmr) will apply to paid events and festivals organised in public spaces. Visitors pay €1.50 each festival day to the event organiser.'
		},
		included_vat: 'INCL. VAT & SERVICECOSTS',
		lead: 'Please check the content of your cart before completing your order.',
		lead_complete: 'Fill in your personal details.',
		lead_upgrade: 'Below you will find an overview of your order.',
		payment_method: {
			select: 'Select a payment method',
			method: 'Payment method',
			zero: 'Select "no payment required" to complete your order.',
			discount_next_step: 'Your discount is deducted from the total amount in the next step'
		},
		payment_status: {
			error: {
				title: 'Payment failed',
				lead: '',
				message:
					"Unfortunately, something went wrong with your payment and your order wasn't successful. It's possible to enter the shop again, but please note that you'll be placed at the back of the waiting list."
			},
			failed: {
				title: 'Payment failed',
				lead: '',
				message:
					"Unfortunately, something went wrong with your payment and your order wasn't successful. It's possible to enter the shop again, but please note that you'll be placed at the back of the waiting list."
			},
			pending: {
				title: 'Payment is pending',
				lead: '',
				message:
					"<h3>Thanks for your order. We are still waiting for final confirmation of your payment by the payment provider.</h3><br><br><h3>Don't worry, normally we get the confirmation within a couple of minutes.</h3><br><br><p>This will happen next:</p><ul><li>Your order and payment will be processed</li><li>You will receive a confirmation e-mail</li><li>You will receive a tracking code as soon as we ship your order (if applicable)</li></ul><br><p>If the money is deducted from your bank account and you do not get a confirmation within 2 hours, please contact <a href='https://customerservice.paylogic.com/' target='_blank'>Paylogic</a>. You can also contact them for any other questions concerning your order.</p>"
			},
			canceled: {
				title: 'Payment cancelled',
				lead: '',
				message:
					"Unfortunately, something went wrong with your payment and your order wasn't successful. It's possible to enter the shop again, but please note that you'll be placed at the back of the waiting list."
			},
			completed: {
				title: 'Thank you',
				lead: '',
				message:
					"Thank you for your order. We are currently processing your order and payment. This is not a confirmation! Your order will be confirmed after you have received a confirmation e-mail. If you don't receive a confirmation e-mail within 2 hours, please check your bank account or credit card to see if the payment was successful."
			},
			paymentProvider: {
				adyen: {
					message:
						"If you have any questions concerning your payment or order, please contact <a target='_blank' href='https://www.justbleep.it/'>Bleep Customer Support</a>."
				},
				paylogic: {
					message:
						"If you have any questions concerning your payment or order, please contact <a target='_blank' href='https://customerservice.paylogic.com/'>Paylogic Customer Support</a>."
				}
			}
		},
		insurance: {
			addon: 'Add refund protection to your new add-ons for just',
			add: 'Add booking protection for all your tickets for just',
			extend: 'Extends the restitution protection for your upgrade for',
			xcover: {
				uri: '<a href="https://www.xcover.com/en/pds/booking-refund-protection-eu" target="_blank">Find Complete Coverage details</a>',
				body: "You're covered if you can't attend for medical reasons (including COVID-19), due to emergency services requiring you to be at home or if authorities issue a travel warning. Refund protection is carried out by our partner XCover."
			}
		},
		orderfee: 'Order fee',
		service_fee: 'Service fee',
		subtotal: 'Subtotal',
		title: 'Your Cart',
		total: 'Total',
		tourist_tax: {
			title: 'Tourist Tax',
			lead: 'This is a local tax imposed by some municipalities and cities, which is used to finance and maintain local facilities. The amount will vary depending on municipality and often is a small amount per person per night. In this case it will be € per person per night'
		},
		voucher: {
			add: 'Add voucher code',
			invalid: 'This voucher code is invalid',
			used: 'This voucher code has already been used',
			redeem: 'Redeem voucher code',
			success: 'Voucher redeemed successfully!'
		}
	},

	// TODO use this translations file to replace the error.json coming in from Contentful
	// ERRORS
	error: {
		form_covid_not_accepted: 'You have not yet agreed with the COVID refund conditions.',
		form_has_errors: 'Please fix the errors in the form to complete your order.',
		form_incomplete: 'Please fill in all required fields *',
		form_no_country_selected: 'You have not yet selected a country to calculate the shipping costs.',
		form_no_insurance_choice: 'Please state whether you want to add a booking protection for your order.',
		form_terms_not_accepted: 'Please accept the terms and conditions to complete your order.',
		generic: 'Try again. Refresh this page and, if necessary, join the queue to access the ticket shop again.',
		invalid_request: 'An invalid request has been made. Please try again.',
		no_config_found: 'An OSS Configuration is missing. Please contact the support.',
		order_lookup_failed:
			'When trying to find your order, an error occurred. Something went wrong on our side. Try it again later',
		order_lookup_invalid: 'Your order number seems invalid, copy the correct number from the Paylogic Mail. ',
		order_lookup_no_order:
			'We cannot find an order with that order number.Copy the correct order number from the Paylogic Mail.',
		page_not_found: 'Page not found.',
		shipping_unavailable: 'Shipping is currently unavailable to your location.',
		undefined: 'An unknown error has occurred.',

		// ALL 1000 ARE UNDEFINED SERVER ERRORS
		// ALL 2000 ARE USER AUTHENTICATED ERRORS
		// ALL 3000 ARE ACCOUNT RELATED ERRORS
		// ALL 4000 ARE GENERAL SHOP ERRORS/MESSAGES
		// ALL 5000 ARE PRODUCT ERRORS
		// ALL 6000 ERRORS ARE DESCISION ERRORS  CLOSE / PROCEED
		// ALL 7000 CODES ARE NOTIFICATIONS
		// ALL 8000 CODES ARE SHOP ERROR CODES
		code: {
			0: '--modal-closed--',
			1000: {
				title: 'Oeps...',
				message: 'Something went wrong. Please try again.'
			},
			2000: {
				title: 'Welcome to the {brand} Shop',
				message: 'Login / create an account to get started.',
				label_cancel: 'CONTINUE'
			},
			2001: {
				title: 'Welcome to the {brand} Shop',
				message: 'Login / create an account to get started.',
				label_cancel: 'CONTINUE'
			},
			2002: {
				title: 'Welcome to the {brand} Shop',
				message: 'Login / create an account to get started.',
				label_cancel: 'CONTINUE'
			},
			3000: {
				title: 'Registration error',
				message:
					"There's no registration linked to this {brand} account. Please contact info@q-dance.com for more information."
			},
			3001: {
				title: 'Registration error',
				message: 'An order has already been placed with this account'
			},
			3002: {
				title: 'Registration error',
				message: 'Registration is closed.'
			},
			3003: {
				title: 'Not Allowed',
				message: 'The shop is not open for you yet. Please check your timeslot and try again.'
			},
			3005: {
				title: 'OOPS... YOU ARE ALREADY DEDIQATED',
				message: 'You can only purchase one membership per account.'
			},
			4000: {
				title: 'The Shop is closed'
			},
			4001: {
				title: 'Thank you',
				message: 'Your wishlist has been successfully saved!'
			},
			4002: {
				title: 'Upgrade error',
				message:
					'Order already processed or not found. If you believe this is an error, click on the email link again.'
			},
			4003: {
				title: 'Upgrade error',
				message:
					'Order already processed or not found. If you believe this is an error, click on the email link again.'
			},
			4004: {
				title: "Time's up",
				message:
					'Due to heavy traffic you can only browse for a limited time. You can re-enter the shop by clicking the link in your e-mail.',
				label_cancel: false
			},
			4005: {
				title: 'No valid order id',
				message:
					'Order already processed or not found. If you believe this is an error, click on the email link again.'
			},
			4006: {
				title: 'No valid access id',
				message: 'You need a valid access id to enter the shop. Try clicking the link in the email again.'
			},
			5000: {
				title: 'Product sold out'
			},
			5001: {
				title: 'Product not found'
			},
			5002: {
				title: 'No travel package(s) found',
				message: 'Please make a different selection'
			},
			5003: {
				title: 'Shipment not Available',
				message:
					'The selected product(s) has/have automatically been removed from your cart. Unfortunately, it is not possible to ship merchandise to the country you have selected.<br>For any questions, contact us at <a href="mailto:merchandise@q-dance.com">merchandise@q-dance.com</a>'
			},
			6000: {
				title: 'Change registration',
				message:
					'Please note that you need to reselect your type of registration (Travel & Stay Packages or ticket only) to enter the member sale. Please complete your registration again at the next step.',
				label_cancel: 'Cancel'
			},
			6001: {
				title: 'Remove item',
				message: 'Are you sure you want to remove this item?',
				label_cancel: 'Cancel'
			},
			6002: {
				title: "Time's up",
				message:
					"It looks like it took too much time to complete your order. Don't worry, you can try again by clicking the button below. Make sure to complete your order within 7 minutes though!",
				label_cancel: false,
				label_confirm: 'Try again'
			},
			6003: {
				title: 'No ticket(s) selected',
				message:
					'Your order contains an accommodation without entrance tickets. Keep in mind that entrance to {festivalId} is only possible with an entrance ticket. Are you sure that you want to continue with your purchase?',
				label_cancel: false,
				label_confirm: 'Continue'
			},
			6004: {
				title: 'Welcome to the {brand} Shop',
				message: 'Login / create an account to get started.',
				label_cancel: false,
				label_confirm: 'Login'
			},
			6005: {
				title: 'Dediqated Member Sale',
				message: '<p>You need a Dediqated Membership to enter this shop.</p>',
				label_cancel: false,
				label_confirm: 'Shop'
			},
			6010: {
				title: 'Are you sure?',
				message: '<p>Are you sure you want to cancel your order?</p>',
				label_cancel: 'No',
				label_confirm: 'Yes'
			},
			7000: {
				title: 'Attention',
				message:
					'The Travel & Stay member sale starts on February 29th. If you want to get acces to it, you will need to change your registration to Travel & Stay. All remaining packages will also go on sale during the ticket member sale on March 7th.'
			},
			8000: {
				title: 'Attention',
				message: ''
			},
			8001: {
				title: 'Attention',
				message: '',
				label_cancel: false
			},
			8002: {
				title: 'An error has occured',
				message: '',
				label_cancel: false
			},
			8003: {
				title: '',
				message: '',
				label_cancel: 'CONTINUE'
			}
		}
	},

	// FORM MESSAGES
	form: {
		address_information: 'Address information',
		birthday: 'Date of birth',
		city: 'City',
		country: 'Country',
		country: {
			label: 'Country'
		},
		countrycode: {
			label: 'Country code'
		},
		email: 'Email address',
		extension: 'Number suffix',
		filter: 'Choose a filter',
		filter_explain: 'Find what you need by filtering through one of the categories below.',
		firstname: 'First name',
		gender: {
			female: 'Female',
			label: 'Gender',
			male: 'Man',
			other: 'Other'
		},
		lastname: 'Last name',
		personal_information: 'Personal information',
		phonenumber: 'Phone number',
		postalcode: 'Postal code',
		select: 'Make a selection',
		shipping_information: 'Shipping information',
		state: {
			label: 'Province'
		},
		state: 'State',
		streetname: 'Street Name',
		streetnumber: 'Street Number'
	},

	// BUTTON AND ANCHOR LABELS
	label: {
		back: 'Back',
		cancel: 'Cancel',
		cart: {
			add: 'Add',
			add_to_cart: 'Add to cart',
			add_to_wishlist: 'Add to wishlist',
			cart: 'Cart',
			complete_payment: 'Complete payment',
			complete_order: 'Finish Order',
			continue_shopping: 'Continue shopping',
			order: 'Ready to order',
			remove: 'Remove',
			remove_from_cart: 'Remove from cart',
			remove_from_wishlist: 'Remove from wishlist',
			replace_item: 'Replace',
			pay: 'Pay',
			save: 'Save pre-registration',
			wishlist: 'Wishlist'
		},
		complete_your_experience: 'Complete your experience',
		find_order: 'Find my order',
		extras: 'extras',
		go_to_checkout: 'Go to checkout',
		go_to_wishlist: 'Go to wishlist',
		merchandise: 'merchandise',
		more_info: 'More info',
		navigation: {
			back: 'Go Back',
			checkout: 'Go to checkout',
			next: 'Next',
			overview: 'Overview',
			previous: 'Previous',
			wishlist: 'Go to wishlist'
		},
		no: 'No',
		reset: 'Reset',
		return_to_homepage: 'Return to the homepage',
		shop: {
			members_sale: 'TICKET SHOP',
			preregistration_sale: 'PRE-REGISTRATION',
			regular_sale: 'TICKET SHOP',
			travel_sale: 'TRAVEL SHOP',
			wishlist: 'WISHLIST SHOP'
		},
		ticket: 'tickets',
		transportation: 'transportation',
		travel: 'travel',
		try_again: 'Try again',
		yes: 'Yes'
	},

	loading: 'Loading...',
	loading_one_moment: 'One moment please...',

	// MODALS
	modal: {
		save_time: {
			body: 'Login in now and save time during checkout',
			cta: 'Login or Register',
			title: 'Save Time'
		}
	},
	// PAGE CONTENT
	page: {
		extras: {
			lead: 'Select your additional products such as parking cards, lockers and other experiences.',
			title: 'Extras & other experiences'
		},
		home: {
			lead: 'Welcome, please choose an event to begin.',
			title: '{brand} One Stop Shop'
		},
		merchandise: {
			lead: 'Select your merchandise.',
			one_size: 'One size fits all',
			title: 'Merchandise'
		},
		shop: {
			experience_prebuilder: {
				form: ['I am interested in', 'I will travel by', 'How many persons?'],
				lead: "Click on 'Tickets Only ' to order tickets. Are you going for the all-in-one experience including accommodations? Click on 'Travel & Stay Packages' for all travel and accommodation packages.",
				title: 'Create your experience'
			},
			experience_builder: {
				choose: 'Choose your',
				select: 'Select your',
				extras: {
					label: 'Extras & other experiences',
					remove: 'Remove',
					update: 'Change/Add'
				},
				lead: 'Which products would you like to add to your cart?',
				merchandise: {
					label: 'Merchandise',
					remove: 'Remove',
					update: 'Change/Add'
				},
				ticket: {
					label: '{event} Tickets',
					remove: 'Remove',
					update: 'Change/Add'
				},
				title: 'Your cart',
				transportation: {
					label: 'Organized bus transport',
					remove: 'Remove',
					update: 'Change/Add'
				},
				travel: {
					label: 'Travel & Stay Packages',
					remove: 'Remove',
					update: 'Update Booking'
				}
			},
			lead: 'Everything you need in one shop.',
			title: 'Shop'
		},
		shop_addon: {
			experience_prebuilder: {
				lead: 'You can now add products such as lockers, parking tickets, travel and accommodation packages and merchandise to your shopping basket.',
				title: "{event} extra's shop"
			},
			lead: 'An overview of your previous order(s).',
			loading: {
				title: 'Checking your previous order(s)...',
				lead: 'We are looking up your previous order (s). Do not refresh this page, loading your previous order (s) can take up to a minute.'
			},
			no_order: {
				lead: 'Enter the order number that you have received in your email (the order number you received from the main booker). Make sure you are logged in with the same account which was used to place your order.',
				title: 'No previous orders have been found.'
			},
			title: 'Your purchased product(s).'
		},
		old: {
			package: {
				addons: 'Je gekozen uitbreidingen in het winkelmandje',
				summary: 'Samenvatting van je gekozen pakket'
			},
			changedorder:
				'Houd er rekening mee dat deze order mogelijk niet de meest recente boekingsgegevens weergeeft, omdat we jouw order op jouw verzoek handmatig hebben gewijzigd. Geen zorgen, we hebben je meest recente boekingsgegevens in ons boekingssysteem en in deze shop vind je alle mogelijke uitbreidingen die geschikt zijn voor je vervoer- en accomodatiepakket.',
			referenceLabel: 'Jouw ordernummer'
		},
		tickets: {
			lead: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
			remove: 'Remove tickets',
			select: 'Select your tickets',
			sold_out: 'Sold Out',
			sold_out_combo: 'Tickets are sold out! A limited amount can still be available in combination with {link}',
			title: 'Tickets',
			upsell_accommodations: 'Accommodation packages are available for you to add to your order.',
			upsell_extras: 'Extra products are available for you to add to your order.',
			upsell_travel: 'Travel packages are available for you to add to your order.'
		},
		transportation: {
			lead: 'Select your organized bus transport.',
			title: 'Organized bus transport'
		},
		travel: {
			lead: 'Select your travel and stay packages.',
			title: 'Travel & Stay Packages'
		}
	},

	remaining: 'remaining',
	// TIME
	time: {
		hour: 'hour | hours',
		hour_abbr: 'h',
		minute: 'minute | minutes',
		minute_abbr: 'min',
		second: 'second | seconds',
		second_abbr: 's'
	},

	// WISHLIST MODAL
	wish_list: {
		checkout: {
			title: 'Your Wishlist'
		},
		experience_builder: {
			title_wishlist: 'Your wishlist',
			lead_wishlist:
				'You can now add products such as tickets, travel and accommodation packages, merchandise and extras to your wish list.Your wish list can be stored in your account.'
		},
		title: 'Your wishlist'
	}
};
