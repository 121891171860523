<template>
	<transition>
		<div class="cart" v-if="totalPrice" @click="expandCart">
			<IconBasket class="cart__icon" />
			<span class="cart__label">{{ totalPrice | formatPrice }}</span>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'CartIndicator',

	computed: {
		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		isMerchandiseSeleced() {
			return this.productsInBasket.hasOwnProperty('merchandise');
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		ignoreOrderFee() {
			return this.$store.getters['shopconfig/getIgnoreOrderFee'];
		},

		isOrderFeeWishlist(){
			return this.shopConfig.orderServiceFeeWishlist
		},

		totalPrice() {
			if (this.shopCart) {
				let total = this.ignoreOrderFee
					? this.shopCart.totalAmountExcludingServiceCosts
					: this.shopCart.totalAmount;
				total = this.isMerchandiseSeleced
					? this.productsInBasket.merchandise.find(item => item.type.slug === 'shipping').type.price + total
					: total;

				if(!!this.isOrderFeeWishlist && this.shopConfig.preregistration){
					total += Number(this.isOrderFeeWishlist)
				}

				return total;
			}
		},

		cartStatus() {
			return !!this.shopCart && this.shopCart.status;
		},
	},

	methods: {
		expandCart() {
			if (this.cartStatus !== 'confirmed' && this.cartStatus !== 'in_payment') {
				this.$store.commit('setShowCheckout', true);
			}
		}
	}
};
</script>

<style lang="scss">
$component: 'cart';

.#{$component} {
	display: flex;

	align-items: center;

	padding: 10px 0;

	font-family: $font-medium;
	font-size: 16px;

	cursor: pointer;

	&__icon {
		margin-top: -1px;
	}

	&__label {
		margin-left: 10px;
	}

	@include sm {
		&__label {
			margin-left: 6px;
		}
	}
}
</style>
